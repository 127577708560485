import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StyledDataGrid from 'components/DataGridTable';
import FormCategoryPage from './FormCategoryPage';
import useQuery from 'hooks/useQuery';
import LinkedProviderPopover from 'components/MenuNew/LinkedProviderPopover';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';
import Preloader from '../../../components/Preloader';

const CategoriesList = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const query = useQuery();
  const entityType = query.get('entityType');
  const mode = query.get('mode');
  const { menu, loading } = useSelector(({ menu }) => menu);
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('batch_report.name'),
        flex: 1,
        sortable: false,
        filterable: true,
        editable: false,
        renderCell: (row) => {
          const { value, id } = row;
          return (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                history.push({
                  search: `?entityType=category&cid=${id || uuidv4()}&mode=edit`,
                });
              }}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '3px',
                  },
                }}
              >
                {value}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'availability',
        headerName: t('schedule.name'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ value }) => {
          return value.map((item, index) => {
            return <Typography>{` ${item.name}${index !== value.length - 1 ? ',' : ''} `}</Typography>;
          });
        },
      },
      {
        field: 'connectedProviders',
        headerName: t('sidebar.providers'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          const { connectedProviders, _id } = row;
          return (
            <LinkedProviderPopover
              targetConnectedProviders={menu.connectedProviders}
              id={_id}
              connectedProviders={connectedProviders}
              target="category"
              targetData={row}
            />
          );
        },
      },
    ],
    [menu.categories, t]
  );
  const createCategory = () => {
    history.push({
      search: `?entityType=category&cid=${uuidv4()}&mode=create`,
    });
  };
  if (entityType) {
    return <FormCategoryPage />;
  }
  if (loading) {
    return <Preloader overlay />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Private permission="MENU_EDIT">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
            <Typography variant="h5">{t('menu.categories')}</Typography>
            <Button id="categoriesList" onClick={createCategory} size="small" variant="outlined">
              {t('menu.new_category')}
            </Button>
          </Box>
        </Private>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2 }}>
          <StyledDataGrid
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={menu.categories}
            columns={columns}
            onRowClick={(data) => {}}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CategoriesList;
