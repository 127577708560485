import React from 'react';

const Preloader = ({ className, overlay = true, calcHeight = false }) => {
  return (
    <div
      className={
        calcHeight ? `preloader-menu ${overlay ? 'overlay' : ''}` : `preloader ${overlay ? 'overlay' : ''} ${className}`
      }
    >
      <div className="loader_block">
        <div className="dot loader_dot_1"></div>
        <div className="dot loader_dot_2"></div>
        <div className="dot loader_dot_3"></div>
      </div>
    </div>
  );
};

export default Preloader;
