import React, { useMemo, useState, useEffect } from 'react';
import Preloader from 'components/Preloader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSwitch from 'components/Forms/CustomSwitch';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { saveAs } from 'file-saver';
import { SIGNUP_REWARDS_AMOUNTS, SIGNUP_REWARDS_EXPIRATION_DATES } from './config';
import { getSignUpRewards, updateSignUpRewards } from 'redux/actions/rewards';
import { dataAdapterSignupRewards, defaultValuesSignUpRewards } from './helper';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const SignupRewards = ({ rewardEnabled }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { currentBusiness } = useSelector(({ business }) => business);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  // const { qr } = useQr(true);

  const rewardSchema = yup.object().shape({
    signupPointsValidityInDays: yup.string().trim().required('field_is_required_'),
    signupRewardAmount: yup.string().trim().required('field_is_required_'),
  });

  const signupRewardsMethods = useForm({
    defaultValues: defaultValuesSignUpRewards,
    mode: 'all',
    resolver: yupResolver(rewardSchema),
  });

  const { reset, getValues } = signupRewardsMethods;

  const downloadQr = () => {
    saveAs(getValues().url, `${currentBusiness.settings.url}-QR.jpg`); // Put your image url here.
  };

  const handleCopy = () => {
    setShowCopyMessage(true);
    navigator?.clipboard?.writeText(getValues().qrUrl);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);
    getSignUpRewards()
      .then((res) => {
        const data = dataAdapterSignupRewards(res.data.data);
        reset({ ...data, qrUrl: res.data.data.qrUrl, url: res.data.data.url });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset]);

  const handleValidSubmit = (values) => {
    setLoading(true);
    const data = dataAdapterSignupRewards(values);

    updateSignUpRewards(data)
      .then((res) => {
        if (!res.error) {
          reset(
            { ...data, qrUrl: res.data.data.qrUrl, url: res.data.data.url },
            { keepTouched: false, keepDirty: false }
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInValidSubmit = (err) => {
    console.log('err: ', err);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ConfirmPromptModal
        handleConfirm={signupRewardsMethods.handleSubmit(handleValidSubmit)}
        submitData={signupRewardsMethods.getValues()}
        hasUnsavedChanges={signupRewardsMethods.formState.isDirty}
        formState={signupRewardsMethods}
      />
      <FormProvider {...signupRewardsMethods}>
        <form onSubmit={signupRewardsMethods.handleSubmit(handleValidSubmit)}>
          {loading && <Preloader overlay />}
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: 3,
                  '& label': {
                    margin: 0,
                  },
                }}
              >
                <CustomSwitch
                  name="enabled"
                  label={
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      {t('Sign up bonus rewards')}
                      <Tooltip sx={{ ml: 0.5 }} title={t('rewards.sign_up_rewards_prodgram')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="signupRewardAmount"
                    label={'Bonus amount *'}
                    helperText={t('The amount your clients will receive for sign up ')}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  >
                    {SIGNUP_REWARDS_AMOUNTS.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="signupPointsValidityInDays"
                    label={'Expiration details *'}
                    helperText={t('The number of days after sign up for reward points to expire.')}
                  >
                    {SIGNUP_REWARDS_EXPIRATION_DATES.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
              <Button size="large" variant="contained" type="submit" disabled={!signupRewardsMethods.formState.isDirty}>
                {t('save')}
              </Button>
            </CardActions>
            {getValues().qrUrl && getValues().url && (
              <Box sx={{ p: 3, mt: -3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Button
                      sx={{
                        color: '#626A7A',
                        borderRadius: '6px',
                        border: ' 1px solid rgba(98, 106, 122, 0.50)',
                      }}
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      onClick={downloadQr}
                    >
                      {t('Download QR')}
                    </Button>
                    <Button
                      sx={{
                        color: '#626A7A',
                        borderRadius: '6px',
                        border: ' 1px solid rgba(98, 106, 122, 0.50)',
                        mt: 2,
                      }}
                      onClick={handleCopy}
                      variant="outlined"
                      startIcon={<ContentCopyIcon />}
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '250px',
                          fontWeight: 500,
                          fontSize: '0.875rem',
                        }}
                      >
                        {getValues().qrUrl}
                      </Typography>
                    </Button>
                  </Box>
                  <Box sx={{ width: '102px', height: '102px', ml: 2 }}>
                    <img style={{ width: '100%', height: '100%' }} src={getValues().url} alt="qr code" />
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '0px',
                      right: '142px',
                      mt: 1,
                      background: '#626A7A',
                      padding: '4px 12px',
                      borderRadius: '6px',
                    }}
                    className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}
                  >
                    <Typography color="white" variant="body2" className="message-text">
                      {t('Copied')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Card>
        </form>
      </FormProvider>
    </Box>
  );
};

export default SignupRewards;
