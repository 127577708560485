import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Icon from '@mui/material/Icon';
import LinkedProviderPopover from 'components/MenuNew/LinkedProviderPopover';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import Preloader from 'components/Preloader';
import { SortableContainer, sortableElement } from 'react-sortable-hoc';
import DragHandle from 'components/DragHandle';
import Private from 'components/Private';
import { arrayReRange, reduceByKey } from 'services/helpers';
import SortableProductItem from './SortableProductItem';
import { reorderProducts } from 'redux/actions/Menu/products';
import { v4 as uuidv4 } from 'uuid';
import variables from 'styles/_variables.scss';
import { useTranslation } from 'react-i18next';
import { SET_PRODUCT_EVENTS_NEXT_LOCATION } from 'components/ConfirmProductActions/constants';

const SortableCategoryItem = sortableElement((props) => {
  const { t } = useTranslation();

  const { category, menu, indexCat, loading, loadingProducts, searchQuery, setFilteredData = () => {} } = props;
  const { _id, name, products } = category;
  const [open, setOpen] = useState({ [_id]: !!(indexCat < 20) });
  const [loadingState, setLoadingState] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let count = 0;

  const filterData = (query, data) => {
    const allData = [];
    if (!query) {
      return data;
    } else {
      const result = data?.filter((items) => {
        if (items.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          allData.push(items);
          return items;
        }
      });

      if (!allData?.length) {
        setFilteredData(allData);
      }
      return result;
    }
  };

  const filtersData = useMemo(() => filterData(searchQuery, products), [searchQuery, products]);
  const filtersDataCategory = useMemo(() => filterData(searchQuery, menu.categories), [searchQuery]);

  useEffect(() => {
    if (searchQuery && filtersData && filtersDataCategory) {
      const allFoundData = [...filtersData, ...filtersDataCategory];
      if (allFoundData?.length) {
        setFilteredData(allFoundData);
      }
    }
  }, [searchQuery, setFilteredData]);

  const handleOpenCategory = (category) => {
    // e.stopPropagation();
    if (category._id) {
      history.push({
        search: `?entityType=category&cid=${category._id}&mode=edit`,
      });
    } else {
      history.push({
        search: `?entityType=category&cid=${uuidv4()}&mode=create`,
      });
    }
  };

  const handleClick = (id) => {
    setOpen({
      ...open,
      [id]: !open[id] || false,
    });
  };

  const handleAddNewProduct = (cid) => {
    history.push({
      search: `?entityType=item&pid=${uuidv4()}&cid=${cid}&mode=create`,
    });
    dispatch({
      type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
      payload: `/menu/overview`,
    });
  };

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const arr1 = Object.keys(reduceByKey(category.products, '_id'));
        const arr2 = arrayReRange(arr1, oldIndex, newIndex);
        dispatch(reorderProducts(arr2, category._id));
      }
    },
    [menu, dispatch]
  );

  const showCategory = (nameCat) => {
    let result = 'none';
    searchQuery &&
      filtersDataCategory.map((item) => {
        if (item.name === nameCat) {
          return (result = 'flex');
        }
      });
    return result;
  };

  return (
    <Box className="nav-item-draggable">
      <Box
        sx={{
          background: category?.bulkInfo?.isSaved ? '' : variables.backgroundBulk,
        }}
      >
        {filtersData?.length || filtersDataCategory?.length || (!filtersData?.length && !searchQuery) ? (
          <ListItem
            className="overview-items-main"
            id={`items-${indexCat}`}
            sx={{
              mb: 0,
              px: 3,
              display: searchQuery && showCategory(name),
            }}
          >
            <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
              <Private permission="MENU_EDIT" disabled={true} background="none">
                <Icon sx={{ color: '#9E9EAD', cursor: 'pointer' }} component={DragHandle} />
              </Private>
              <div>
                <LinkedProviderPopover
                  id={_id}
                  overrideStyle={{
                    background: category?.bulkInfo?.isSaved ? '' : variables.borderBulk,
                  }}
                  connectedProviders={category.connectedProviders}
                  target="category"
                  targetData={category}
                  targetConnectedProviders={menu.connectedProviders}
                />
              </div>

              <Icon
                onClick={() => handleClick(_id)}
                sx={{ color: '#9E9EAD', cursor: 'pointer', fontSize: '20px', ml: 2, mr: '12px' }}
                component={open[_id] ? ExpandLess : ExpandMore}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => handleOpenCategory(category)}
              primary={
                <Link to="?entityType=category">
                  <Box display="flex">
                    <Typography sx={{ fontSize: '14px', color: '#141414', fontWeight: 600 }} variant="body2">
                      {filtersDataCategory?.length && searchQuery
                        ? filtersDataCategory.map((item) => {
                            if (item.name === name && count === 0) {
                              count += 1;
                              return item.name;
                            }
                            return '';
                          })
                        : name}
                    </Typography>
                    <Typography
                      sx={{ color: '#434343', fontSize: '12px', ml: 1 }}
                    >{`(Number of items: ${category?.productsCount} )`}</Typography>
                  </Box>
                </Link>
              }
            />
          </ListItem>
        ) : (
          <></>
        )}
        <Collapse sx={{ background: 'white' }} in={searchQuery ? !!searchQuery : open[_id]} timeout="auto">
          <List sx={{ ml: 3 }} component="div" disablePadding>
            <SortableList onSortEnd={handleSortEnd} useDragHandle>
              {loadingProducts || loadingState ? <Preloader overlay={true} /> : ''}
              {filtersData?.length || (!filtersData?.length && !searchQuery) ? (
                <>
                  {filtersData?.map((product, index) => (
                    <SortableProductItem
                      key={product._id}
                      index={index}
                      menu={menu}
                      loading={loading}
                      cid={category._id}
                      product={product}
                      category={category}
                    />
                  ))}
                  <Private permission="MENU_EDIT">
                    <ListItemButton id="sortableElementAdd" key={0} sx={{ px: 8 }}>
                      <ListItemText
                        onClick={() => handleAddNewProduct(category._id)}
                        primary={
                          <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>+ {t('menu.add_item')}</Typography>
                        }
                      />
                    </ListItemButton>
                  </Private>
                </>
              ) : (
                <></>
              )}
            </SortableList>
          </List>
        </Collapse>
      </Box>
    </Box>
  );
});

const SortableList = SortableContainer(({ children }) => {
  const { t } = useTranslation();

  return (
    <Box vertical className="menu-categories-list">
      {children === undefined || children.length === 0 ? (
        <div className="menu-content">
          <div className="menu-content-body">
            <EmptyMenu text={t('menu.you_dont_have_products_yet')} />
          </div>
        </div>
      ) : (
        children
      )}
    </Box>
  );
});

export default SortableCategoryItem;
