import React, { useEffect, useMemo, useState } from 'react';
import Main from 'pages/Main';
import Auth from 'pages/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { useClearCacheCtx } from 'react-clear-cache';
import { removeItemAfterSignUp } from './utils/soldOut';
import GoogleTagWidget from 'components/GoogleTagWidget';
import { useHistory } from 'react-router-dom';
import SetupInterceptorAccessDenied from 'components/AccessDenied';
import ConfirmProductActions from 'components/ConfirmProductActions';
import {
  EventStatusesEnum,
  EventTypeEnum,
  ModalOpenStatusEnum,
  SET_MODAL_ACTIONS,
  SET_PRODUCT_EVENTS,
  SET_PRODUCT_EVENTS_NEXT_LOCATION,
} from 'components/ConfirmProductActions/constants';
import { v4 as uuidv4 } from 'uuid';
import { createCachingEvent } from 'components/ConfirmProductActions/service';
import { linkedWithProvider } from 'helpers/linkedWithProvider';
import useProviderConfig from 'hooks/useProviderConfig';

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authorized, data } = useSelector(({ user }) => user);
  const { menu } = useSelector(({ menu }) => menu);
  const { menu: mainMenu } = useSelector((store) => store);
  const productEvents = useSelector((store) => store.productEvents);
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const { providers } = useProviderConfig();
  const userId = data?._id || '';
  const [nextLoc, setNextLoc] = useState(null);
  const categoriesBulk = menu?.categories?.find((cat) => !cat?.bulkInfo?.isSaved);
  const productsBulk = menu?.products?.find((product) => !product?.bulkInfo?.isSaved);
  const menuBulk = !menu?.bulkInfo?.isSaved;
  const isBulked = menuBulk || categoriesBulk || productsBulk;
  const menuLength = menu?.categories?.length;

  SetupInterceptorAccessDenied(history);

  const linked = useMemo(() => {
    return menu.connectedProviders && linkedWithProvider(menu.connectedProviders, providers);
  }, [menu]);

  const historyBlock = (nextLocation) => {
    if (isBulked && history.location.pathname.includes('menu') && menuLength && linked) {
      setNextLoc(nextLocation?.pathname);
      dispatch({
        type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
        payload: `${nextLocation.pathname}${nextLocation.search}`,
      });
      if (menu?.hasOwnProperty('bulkInfo') && isBulked && !nextLocation.pathname.includes('menu')) {
        createCachingEvent({
          uuid: uuidv4(),
          status: EventStatusesEnum.created,
          type: EventTypeEnum.notice,
          metadata: {
            menuIds: [],
            source: 'menu',
          },
          tag: 'unpushedChanges',
          editorId: userId,
          acknowledgement: 'manual',
          serviceSource: 'mms',
        }).then((res) => {
          const { data } = res;
          dispatch({
            type: SET_PRODUCT_EVENTS,
            payload: [data?.data],
          });
          dispatch({
            type: SET_MODAL_ACTIONS,
            payload: ModalOpenStatusEnum.opened,
          });
        });

        return false;
      } else {
        return true;
      }
    } else {
      dispatch({
        type: SET_PRODUCT_EVENTS,
        payload: [],
      });
    }
  };

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }

    if (
      !history.location.pathname.includes('/signup/accountInfo') &&
      !history.location.pathname.includes('/signup/legalInfo') &&
      !history.location.pathname.includes('/signup/billingInfo') &&
      !history.location.pathname.includes('/location/invitation')
    ) {
      removeItemAfterSignUp();
    }
  }, []);

  useEffect(() => {
    if (isBulked) {
      const unblock = history.block(historyBlock);
      return () => {
        unblock();
      };
    }
  }, [
    history,
    isBulked,
    history.location.pathname,
    history.location.search,
    historyBlock,
    menu?.categories,
    menu?.modifiersAll,
  ]);

  useEffect(() => {
    if (productEvents?.modalStatus === ModalOpenStatusEnum.confirm) {
      if (
        (nextLoc && !nextLoc.includes('/menu/overview')) ||
        (!mainMenu?.menuAvailable && nextLoc && !nextLoc?.includes('menu'))
      ) {
        if (!nextLoc.includes('/menu')) {
          window.location.pathname = nextLoc;
        } else {
          history.push(nextLoc);
          window.location.reload();
        }
      }
    }
  }, [nextLoc, productEvents?.modalStatus]);

  if (authorized) {
    return (
      <GoogleTagWidget>
        {linked && productEvents.eventData[0]?.tag && productEvents.eventData[0]?.tag === 'unpushedChanges' ? (
          <ConfirmProductActions formData={null} handleSubmit={() => {}} />
        ) : (
          <></>
        )}

        <Main />
      </GoogleTagWidget>
    );
  } else {
    return (
      <GoogleTagWidget>
        <Auth />
      </GoogleTagWidget>
    );
  }
}

export default App;
