import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import ModifierForm from 'components/MenuNew/FormView/ModifierFormGroup/index';
import Preloader from 'components/Preloader';
import useQuery from 'hooks/useQuery';
import FormFooter from './FormFooter';
import {
  addModifier,
  deleteModifier,
  editModifierV2,
  getModifiersAll,
  groupModifier,
} from 'redux/actions/Menu/modifiers';
import FormHeader from 'pages/MenuNew/FormsView/NewModifierGroup/FormHeader';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { makeStyles } from '@mui/styles';
import { getModifierDataById } from 'redux/actions/Menu/modifiers';
import { CONTEXT_TYPES } from 'constants/menu';
import Private from 'components/Private';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';
import { addDefaultPriceOverride } from 'utils/menu';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTranslation } from 'react-i18next';
import { SET_LOADING } from 'redux/actions/types';
import { ModalOpenStatusEnum, SET_MODAL_ACTIONS, SET_PRODUCT_EVENTS } from 'components/ConfirmProductActions/constants';
import { set } from 'lodash';
import { NAME_ENUMS } from 'constants/products';

const initialData = {
  min: null,
  max: null,
  free: '',
  canSelectMultipleOptions: false,
  maxTotalNumberOfModifierItems: '',
  minTotalNumberOfModifierItems: '',
  minAnySingleModifierItems: '',
  maxAnySingleModifierItems: '',
  name: '',
  title: '',
  description: '',
  list: [
    {
      name: '',
      price: 0,
      priceOverride: [],
      optionSort: 0,
    },
  ],
  isActive: true,
  internalName: '',
  isOnePrice: true,
};

const useStyles = makeStyles((theme) => ({
  preloader: {
    position: 'fixed !important',
  },
}));

function compare(a, b) {
  if (a.sort < b.sort) {
    return -1;
  }
  if (a.sort > b.sort) {
    return 1;
  }
  return 0;
}

const FormData = ({
  setCurrentModifier = () => {},
  currentModifier,
  currentProduct,
  modifierMethods,
  setTabValue,
  currentCategory,
  setTitleModifier = () => {},
  isDirty,
  setIsDirty,
  setModifierFormState,
  formErrors,
  updateFormErrors = () => {},
  isChanged,
  setIsChanged,
  handleErrors = () => {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isInOverview = history.location.pathname.includes('/overview');
  const isInModifierGroupPage = history.location.pathname.includes('/menu/modifierGroups');
  const [loading, setLoading] = useState(false);
  const { menu } = useSelector(({ menu }) => menu);
  const query = useQuery();
  const mode = query.get('mode');
  const productId = query.get('pid');
  const categoryId = query.get('cid');
  const modifierId = query.get('mid');
  const entityType = query.get('entityType');
  const isInItemMotAdd = history.location.pathname.includes('/items') && entityType === 'modifier';

  const [formState, updateFormState] = useState(initialData);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (mode === 'edit') {
      setLoading(true);
      dispatch(getModifierDataById(modifierId))
        .then((res) => {
          if (!res.title) {
            res.title = res.name;
          }
          if (!res.description) {
            res.description = '';
          }
          if (!res.free) {
            res.free = '';
          }
          updateFormState(res);
          setTitleModifier(res.name);
          setCurrentModifier(res);
        })
        .finally(() => {
          dispatch({ type: SET_LOADING, payload: false });
          setLoading(false);
        });
    } else {
      let connectedProviders;
      if (isInModifierGroupPage) {
        connectedProviders = menu.connectedProviders;
      } else {
        connectedProviders = currentProduct?.connectedProviders;
      }
      if (connectedProviders) {
        Object.keys(connectedProviders).map((item) => {
          if (currentProduct?.connectedProviders[item]) {
            initialData.list[0].priceOverride.push({
              context_type: 'MOD_ITEM',
              context_value: item,
              price: +currentProduct?.price || '',
            });
          }
        });
      }
      setTitleModifier('');
      setCurrentModifier(initialData);
      const initData = {
        min: null,
        max: null,
        free: '',
        canSelectMultipleOptions: false,
        maxTotalNumberOfModifierItems: '',
        minTotalNumberOfModifierItems: '',
        minAnySingleModifierItems: '',
        maxAnySingleModifierItems: '',
        name: '',
        title: '',
        description: '',
        list: [
          {
            name: '',
            price: '',
            priceOverride: [],
            optionSort: 0,
          },
        ],
        isActive: true,
        internalName: '',
        isOnePrice: true,
      };
      updateFormState(initData);
    }
  }, [modifierId]);

  useEffect(() => {
    if (isInOverview) {
      if (history.location.state === 'changeMod') {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }

      setModifierFormState({ ...formState });
    }
  }, [formState]);

  const validateModifiersMinMax = (formStateData) => {
    const isZero = (value) => value === 0;
    let copyFormErrors = { ...formErrors };
    let hasError = false;
    let hasErrorMax = false;

    const checkEmptyOnlyZero = (fieldValue, errorName, errorMessage) => {
      if (isZero(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasError = true;
      }
    };
    const checkEmptyOnlyZeroMax = (fieldValue, errorName, errorMessage) => {
      if (isZero(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasErrorMax = true;
      }
    };
    checkEmptyOnlyZero(formStateData?.min, NAME_ENUMS.min, 'menu.min_number');
    checkEmptyOnlyZeroMax(formStateData?.max, NAME_ENUMS.max, 'menu.min_number');
    if (hasError || hasErrorMax) {
      updateFormErrors({ ...copyFormErrors });
      return false;
    }

    return true;
  };

  const validateModifiers = (formStateData) => {
    const isEmptyWithZero = (value) => value === undefined || value === null || value === '' || Number(value) === 0;
    const isEmpty = (value) => value === undefined || value === null || value === '';
    const isZero = (value) => value === 0;

    let copyFormErrors = { ...formErrors };
    let hasError = false;
    let hasEmptyError = false;
    let hasLastError = false;
    let hasMostError = false;

    // Check for empty values where zero is considered invalid
    const checkEmptyWithZero = (fieldValue, errorName, errorMessage) => {
      if (isEmptyWithZero(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasError = true;
      }
    };
    const checkEmptyOnlyZero = (fieldValue, errorName, errorMessage) => {
      if (isZero(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasLastError = true;
      }
    };

    const checkEmptyOnlyZeroMax = (fieldValue, errorName, errorMessage) => {
      if (isZero(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasMostError = true;
      }
    };

    // Check for empty values where zero is not considered invalid
    const checkEmpty = (fieldValue, errorName, errorMessage) => {
      if (isEmpty(fieldValue)) {
        copyFormErrors = set(copyFormErrors, errorName, { error: true, message: errorMessage });
        hasEmptyError = true;
      }
    };

    // Validate fields
    checkEmpty(formStateData?.minTotalNumberOfModifierItems, NAME_ENUMS.minT, 'This field is required');
    checkEmptyWithZero(formStateData?.maxTotalNumberOfModifierItems, NAME_ENUMS.maxT, 'menu.min_number');

    checkEmptyOnlyZero(formStateData?.min, NAME_ENUMS.min, 'menu.min_number');
    checkEmptyOnlyZeroMax(formStateData?.max, NAME_ENUMS.max, 'menu.min_number');

    checkEmptyWithZero(formStateData?.minAnySingleModifierItems, NAME_ENUMS.minAnySingle, 'menu.min_number');
    checkEmptyWithZero(formStateData?.maxAnySingleModifierItems, NAME_ENUMS.maxAnySingle, 'menu.min_number');

    if (hasError || hasLastError || hasMostError || hasEmptyError) {
      updateFormErrors({ ...copyFormErrors });
      return false;
    }

    return true;
  };

  const handleValidSubmit = (val, e) => {
    if (setIsDirty) {
      setIsDirty(false);
    }
    if (setTabValue) {
      setTabValue(null, 1);
    }
    if (e) {
      e?.preventDefault();
    } else if (val) {
      val?.preventDefault();
    }
    if (formState?.canSelectMultipleOptions) {
      const isValidOption = validateModifiers(formState);
      if (!isValidOption) return;
    } else {
      const isValidOption = validateModifiersMinMax(formState);
      if (!isValidOption) return;
    }

    const priceError = formErrors?.list?.filter((item) => item?.price?.error) || [];
    const priceOverrideError =
      formErrors?.list?.filter((item) => {
        const findErr = item?.priceOverride?.find((priceOverride) => {
          return priceOverride?.price?.error;
        });
        if (findErr) {
          return findErr;
        }
      }) || [];

    if (handleErrors(formState) || priceError.length || formErrors?.['title']?.error) {
      return;
    }
    const isError = formErrors && Object.values(formErrors)?.find((err) => err.error)?.error;

    if (isError || priceOverrideError?.length) {
      return;
    }
    formState.list.forEach((item, index) => {
      item.optionSort = index;
      item.priceOverride?.forEach((modItem) => {
        if (modItem.context_type === 'MOD_ITEM') {
          modItem.locked = false;
          modItem.pickupPrice = modItem.price;
          modItem?.priceOverride?.forEach((modPriceOverride) => {
            modPriceOverride.pickupPrice = modPriceOverride.price;
            modPriceOverride.locked = true;
          });
        }
      });
    });
    setLoading(true);
    if (mode != 'edit') {
      dispatch(addModifier({ ...formState }))
        .then((res) => {
          if (!isInModifierGroupPage) {
            goToModifierOptions(res);
          } else {
            if (isInOverview) {
              history.push({
                search: `entityType=modifier&mid=${res._id}&mode=edit`,
              });
            } else {
              history.push({
                search: ``,
              });
            }

            if (setIsDirty) {
              setIsDirty(false);
            }
            if (setIsChanged) {
              setIsChanged(false);
            }
          }
        })
        .finally(() => {
          // reset(values);
          setLoading(false);
        });
    } else {
      addDefaultPriceOverride(currentCategory, menu, CONTEXT_TYPES, formState);
      dispatch(editModifierV2({ ...formState }))
        .then((res) => {
          if (setIsDirty) {
            setIsDirty(false);
          }
          if (setIsChanged) {
            setIsChanged(false);
          }
          if (e) {
            if (setIsDirty) {
              setIsDirty(false);
            }
            const modIds = [...currentProduct.modifiers?.map((item) => item._id), res._id];
            groupModifier(modIds, productId).then((res) => {
              if (res?.data?.events?.length) {
                dispatch({ type: SET_PRODUCT_EVENTS, payload: res?.data?.events });
                localStorage.setItem('SET_MODAL_ACTIONS', ModalOpenStatusEnum.opened);
                dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.opened });
              }
            });
          } else {
            goToModifierOptions(res);
          }
        })
        .finally(() => {
          // reset(values);
          setLoading(false);
        });
    }
  };

  const goToModifierOptions = (res = null) => {
    const isError = formErrors && Object.values(formErrors)?.find((err) => err.error)?.error;
    if (isError) {
      return;
    }

    if (!isInModifierGroupPage) {
      const exist = modifierMethods.fields.findIndex((item) => item?._id === res?._id);
      if (exist !== -1) {
        modifierMethods.update(exist, res);
      } else {
        modifierMethods.append(res);
      }

      setTabValue(null, 1);
      history.push({
        search: `?entityType=item&pid=${productId}&cid=${categoryId}&mode=edit`,
        state: { create: true },
      });
    } else {
      history.push({
        search: `entityType=modifier&mid=${res?._id || currentModifier?._id}&mode=edit`,
        state: { create: true },
      });
    }
    setChanged(false);
  };

  // const changeIsOnePrice = (checked) => {
  //   updateFormState({ ...formState, isOnePrice: !checked });
  // };

  const handleDelete = async () => {
    const confirmed = await confirm({
      title: 'confirm_modal.delete_modifier',
      message: 'settings.are_you_sure_delete_modifier_group_add_to',
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      setLoading(true);
      dispatch(deleteModifier(modifierId)).finally(() => {
        history.push('/menu/modifierGroups');
        // reset(initialData);
        setLoading(false);
        dispatch(getMenusV2());
      });
      dispatch(getModifiersAll());
    }
  };

  const handleCancel = () => {
    setIsDirty(false);
    updateFormState({ ...formState });
    if (isInOverview) {
      history.push({
        search: `?entityType=item&pid=${productId}&cid=${categoryId}&mode=edit`,
        state: { cancel: true },
      });
    } else {
      history.push({
        search: `?modifierGroups`,
        state: { cancel: true },
      });
    }
  };

  return (
    <form style={{ height: 'inherit' }} onSubmit={handleValidSubmit}>
      <ConfirmPromptModal
        handleConfirm={handleValidSubmit}
        notHookForm={true}
        submitData={formState}
        hasUnsavedChanges={isDirty}
        isFormError={formErrors}
      />

      {loading && <Preloader overlay={true} className={classes.preloader} />}
      {isInModifierGroupPage ? (
        <Box>
          <FormHeader title={formState.name} handleDelete={handleDelete} mode={mode} />
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ height: 'calc(100% - 174.5px)', overflowY: 'auto', px: 4, py: 3 }}>
        <Private permission="MENU_EDIT" disabled={true}>
          <ModifierForm
            isInOverview={isInOverview}
            isInModifierGroupPage={isInModifierGroupPage}
            mode={mode}
            // changeIsOnePrice={changeIsOnePrice}
            currentProduct={currentProduct}
            formState={formState}
            formErrors={formErrors}
            setIsDirty={setIsDirty}
            setIsChanged={setIsChanged}
            setChanged={setChanged}
            updateFormErrors={updateFormErrors}
            updateFormState={updateFormState}
            handleErrors={handleErrors}
          />
        </Private>
      </Box>
      <Box>
        <FormFooter
          isDisable={isDirty || isChanged || changed}
          isInOverview={isInOverview}
          isInItemMotAdd={isInItemMotAdd}
          handleCancel={handleCancel}
          mode={mode}
        />
      </Box>
    </form>
  );
};

export default memo(FormData);
